@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@350&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@350&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
.container{
    background-color: #ffa500;
    width: 100%;
}
.social_icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.modal__links{
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.one__modal__link{
    cursor: pointer;
    margin: 10px 40px;
    font-size: 14px;
    color: white;
    transition: transform 0.2s;
    text-decoration: underline;

}
.one__modal__link:hover{
transform: scale(1.4);
    color: #CC0000;
    text-decoration: none;
}
.contact__us{
    text-align: center;
    min-width: 100%;
    margin: 20px auto 30px auto;
    font-size: 14px;
    color: #CC0000;
}
.one_icon{
    width: 40px;
    margin: 40px 10px 10px 10px ;
    transition: transform 0.2s;
}
.one_icon:hover{
    background-color: white;
    border-radius: 500px;
    transform: scale(1.5) translateY(-30%);
    box-shadow: 3px 2px 3px  rgba(255, 255, 255, 0.521);
}
.modal__container{    
    transform: translateY(-150%) translateX(30%);
    min-width: 60%;
    max-width: 60%;
    min-height: 40vh;
    max-height: 40vh;
    position: absolute;
    z-index: 999 !important;
    border: 2px solid #ffa500;
    border-radius: 10px;
    background-color: #CC0000;
    padding: 10px;
    margin: auto;
    overflow-y: scroll;
}
.modal__title{    
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
}
.modal__body{
    margin: 25px 0 10px 0;
    min-width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: 15px;
    color: white;
}
/* required styles */

.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon .leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}
.leaflet-container {
  overflow: hidden;
}
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow {
  -webkit-user-select: none;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-drag: none;
}
/* Prevents IE11 from highlighting tiles in blue */
.leaflet-tile::selection {
  background: transparent;
}
/* Safari renders non-retina tile on retina better with this, but Chrome is worse */
.leaflet-safari .leaflet-tile {
  image-rendering: -webkit-optimize-contrast;
}
/* hack that prevents hw layers "stretching" when loading new tiles */
.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  -webkit-transform-origin: 0 0;
}
.leaflet-marker-icon,
.leaflet-marker-shadow {
  display: block;
}
/* .leaflet-container svg: reset svg max-width decleration shipped in Joomla! (joomla.org) 3.x */
/* .leaflet-container img: map is broken in FF if you have max-width: 100% on tiles */
.leaflet-container .leaflet-overlay-pane svg,
.leaflet-container .leaflet-marker-pane img,
.leaflet-container .leaflet-shadow-pane img,
.leaflet-container .leaflet-tile-pane img,
.leaflet-container img.leaflet-image-layer,
.leaflet-container .leaflet-tile {
  max-width: none !important;
  max-height: none !important;
}

.leaflet-container.leaflet-touch-zoom {
  touch-action: pan-x pan-y;
}
.leaflet-container.leaflet-touch-drag {
  /* Fallback for FF which doesn't support pinch-zoom */
  touch-action: none;
  touch-action: pinch-zoom;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  touch-action: none;
}
.leaflet-container {
  -webkit-tap-highlight-color: transparent;
}
.leaflet-container a {
  -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
}
.leaflet-tile {
  filter: inherit;
  visibility: hidden;
}
.leaflet-tile-loaded {
  visibility: inherit;
}
.leaflet-zoom-box {
  width: 0;
  height: 0;
  box-sizing: border-box;
  z-index: 800;
}
/* workaround for https://bugzilla.mozilla.org/show_bug.cgi?id=888319 */
.leaflet-overlay-pane svg {
  -moz-user-select: none;
}

.leaflet-pane {
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}
.leaflet-overlay-pane {
  z-index: 400;
}
.leaflet-shadow-pane {
  z-index: 500;
}
.leaflet-marker-pane {
  z-index: 600;
}
.leaflet-tooltip-pane {
  z-index: 650;
}
.leaflet-popup-pane {
  z-index: 700;
}

.leaflet-map-pane canvas {
  z-index: 100;
}
.leaflet-map-pane svg {
  z-index: 200;
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}
.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

/* control positioning */

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}
.leaflet-top,
.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
}
.leaflet-top {
  top: 0;
}
.leaflet-right {
  right: 0;
}
.leaflet-bottom {
  bottom: 0;
}
.leaflet-left {
  left: 0;
}
.leaflet-control {
  float: left;
  clear: both;
}
.leaflet-right .leaflet-control {
  float: right;
}
.leaflet-top .leaflet-control {
  margin-top: 10px;
}
.leaflet-bottom .leaflet-control {
  margin-bottom: 10px;
}
.leaflet-left .leaflet-control {
  margin-left: 10px;
}
.leaflet-right .leaflet-control {
  margin-right: 10px;
}

/* zoom and fade animations */

.leaflet-fade-anim .leaflet-tile {
  will-change: opacity;
}
.leaflet-fade-anim .leaflet-popup {
  opacity: 0;
  transition: opacity 0.2s linear;
}
.leaflet-fade-anim .leaflet-map-pane .leaflet-popup {
  opacity: 1;
}
.leaflet-zoom-animated {
  transform-origin: 0 0;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  will-change: transform;
}
.leaflet-zoom-anim .leaflet-zoom-animated {
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
}
.leaflet-zoom-anim .leaflet-tile,
.leaflet-pan-anim .leaflet-tile {
  transition: none;
}

.leaflet-zoom-anim .leaflet-zoom-hide {
  visibility: hidden;
}

/* cursors */

.leaflet-interactive {
  cursor: pointer;
}
.leaflet-grab {
  cursor: grab;
}
.leaflet-crosshair,
.leaflet-crosshair .leaflet-interactive {
  cursor: crosshair;
}
.leaflet-popup-pane,
.leaflet-control {
  cursor: auto;
}
.leaflet-dragging .leaflet-grab,
.leaflet-dragging .leaflet-grab .leaflet-interactive,
.leaflet-dragging .leaflet-marker-draggable {
  cursor: move;
  cursor: grabbing;
}

/* marker & overlays interactivity */
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-image-layer,
.leaflet-pane > svg path,
.leaflet-tile-container {
  pointer-events: none;
}

.leaflet-marker-icon.leaflet-interactive,
.leaflet-image-layer.leaflet-interactive,
.leaflet-pane > svg path.leaflet-interactive,
svg.leaflet-image-layer.leaflet-interactive path {
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
  pointer-events: auto;
}

/* visual tweaks */

.leaflet-container {
  background: #ddd;
  outline: 0;
}
.leaflet-container a {
  color: #0078a8;
}
.leaflet-container a.leaflet-active {
  outline: 2px solid orange;
}
.leaflet-zoom-box {
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

/* general typography */
.leaflet-container {
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
}

/* general toolbar styles */

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
}
.leaflet-bar a,
.leaflet-bar a:hover {
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar a,
.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
}
.leaflet-bar a:hover {
  background-color: #f4f4f4;
}
.leaflet-bar a:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-bar a:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.leaflet-bar a.leaflet-disabled {
  cursor: default;
  background-color: #f4f4f4;
  color: #bbb;
}

.leaflet-touch .leaflet-bar a {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.leaflet-touch .leaflet-bar a:first-child {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.leaflet-touch .leaflet-bar a:last-child {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

/* zoom control */

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-touch .leaflet-control-zoom-in,
.leaflet-touch .leaflet-control-zoom-out {
  font-size: 22px;
}

/* layers control */

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: #fff;
  border-radius: 5px;
}
.leaflet-control-layers-toggle {
  background-image: url(/static/media/user.68cb0bff.png);
  width: 36px;
  height: 36px;
}
.leaflet-retina .leaflet-control-layers-toggle {
  background-image: url(/static/media/user.68cb0bff.png);
  background-size: 26px 26px;
}
.leaflet-touch .leaflet-control-layers-toggle {
  width: 44px;
  height: 44px;
}
.leaflet-control-layers .leaflet-control-layers-list,
.leaflet-control-layers-expanded .leaflet-control-layers-toggle {
  display: none;
}
.leaflet-control-layers-expanded .leaflet-control-layers-list {
  display: block;
  position: relative;
}
.leaflet-control-layers-expanded {
  padding: 6px 10px 6px 6px;
  color: #333;
  background: #fff;
}
.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}
.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}
.leaflet-control-layers label {
  display: block;
}
.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid #ddd;
  margin: 5px -10px 5px -6px;
}

/* Default icon URLs */
.leaflet-default-icon-path {
  background-image: url(/static/media/user.68cb0bff.png);
}

/* attribution and scale controls */

.leaflet-container .leaflet-control-attribution {
  background: #fff;
  background: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.leaflet-control-attribution,
.leaflet-control-scale-line {
  padding: 0 5px;
  color: #333;
}
.leaflet-control-attribution a {
  text-decoration: none;
}
.leaflet-control-attribution a:hover {
  text-decoration: underline;
}
.leaflet-container .leaflet-control-attribution,
.leaflet-container .leaflet-control-scale {
  font-size: 11px;
}
.leaflet-left .leaflet-control-scale {
  margin-left: 5px;
}
.leaflet-bottom .leaflet-control-scale {
  margin-bottom: 5px;
}
.leaflet-control-scale-line {
  border: 2px solid #777;
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;

  background: #fff;
  background: rgba(255, 255, 255, 0.5);
}
.leaflet-control-scale-line:not(:first-child) {
  border-top: 2px solid #777;
  border-bottom: none;
  margin-top: -2px;
}
.leaflet-control-scale-line:not(:first-child):not(:last-child) {
  border-bottom: 2px solid #777;
}

.leaflet-touch .leaflet-control-attribution,
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  box-shadow: none;
}
.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

/* popup */

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
}
.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.leaflet-popup-content {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  margin: 13px 19px;
  line-height: 1.4;
}
.leaflet-popup-content p {
  margin: 18px 0;
}
.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
}
.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;

  margin: -10px auto 0;
  transform: rotate(45deg);
}
.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  background: white;
  color: #333;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}
.leaflet-container a.leaflet-popup-close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  border: none;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.leaflet-container a.leaflet-popup-close-button:hover {
  color: #999;
}
.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.leaflet-oldie .leaflet-popup-content-wrapper {
  zoom: 1;
}
.leaflet-oldie .leaflet-popup-tip {
  width: 24px;
  margin: 0 auto;

  -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678)";
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.70710678, M12=0.70710678, M21=-0.70710678, M22=0.70710678);
}
.leaflet-oldie .leaflet-popup-tip-container {
  margin-top: -1px;
}

.leaflet-oldie .leaflet-control-zoom,
.leaflet-oldie .leaflet-control-layers,
.leaflet-oldie .leaflet-popup-content-wrapper,
.leaflet-oldie .leaflet-popup-tip {
  border: 1px solid #999;
}

/* div icon */

.leaflet-div-icon {
  background: #fff;
  border: 1px solid #666;
}

/* Tooltip */
/* Base styles for the element that has a tooltip */
.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 3px;
  color: #222;
  white-space: nowrap;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}
.leaflet-tooltip-top:before,
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  position: absolute;
  pointer-events: none;
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Directions */

.leaflet-tooltip-bottom {
  margin-top: 6px;
}
.leaflet-tooltip-top {
  margin-top: -6px;
}
.leaflet-tooltip-bottom:before,
.leaflet-tooltip-top:before {
  left: 50%;
  margin-left: -6px;
}
.leaflet-tooltip-top:before {
  bottom: 0;
  margin-bottom: -12px;
  border-top-color: #fff;
}
.leaflet-tooltip-bottom:before {
  top: 0;
  margin-top: -12px;
  margin-left: -6px;
  border-bottom-color: #fff;
}
.leaflet-tooltip-left {
  margin-left: -6px;
}
.leaflet-tooltip-right {
  margin-left: 6px;
}
.leaflet-tooltip-left:before,
.leaflet-tooltip-right:before {
  top: 50%;
  margin-top: -6px;
}
.leaflet-tooltip-left:before {
  right: 0;
  margin-right: -12px;
  border-left-color: #fff;
}
.leaflet-tooltip-right:before {
  left: 0;
  margin-left: -12px;
  border-right-color: #fff;
}

/* XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX */
.yellowStrip {
  padding: 5px;
  max-width: 100%;
  justify-content: space-around;
  background-color: #ff8400;
  display: flex;
  flex-wrap: wrap;
}
.user__places{
  display: flex;
  flex-wrap: wrap;
  justify-content:space-evenly;
  max-width: 80%;
  min-width: 80%;
  margin: 0 0 4px 0;
}
.user__places__with__places{
  display: flex;
  flex-wrap: wrap;
  justify-content:left;
  max-width: 80%;
  min-width: 80%;
  margin: 0 0 4px  0;
}
.addButton {
  align-self: right;
  margin-right: 3vw;
  padding: 0px 10px;
  display: flex;
  width: 7vw;
  font-weight: bolder;
  color: #CC0000;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  background-color: white;
  align-items: center;
  box-shadow: 0px 6px 5px 5px #05050517;
  text-align: center;
  max-height: 6vh;
  min-height: 6vh;
}
.normalButton{
	display: flex;
	min-width: 20%;
  font-weight: bold;
  color: white;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  text-align: center;
  max-height: 6vh;
  min-height: 6vh;
  border-radius: 100px;
  background: #05050517;
  margin: 0 10px;
}
.normalButtonText{
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  max-height: 6vh;
  min-height: 6vh;
  left: 0;
  padding: 0 20px;
}
.normalButtonLong{
	display: flex;
  align-items: center;
  justify-content: center;
  background: #05050517;
  border-radius: 100px;
  width: 100%;
  font-weight: bolder;
  color: white;
  text-align: center;
  max-height: 6vh;
  min-height: 6vh;
}
.inputField{
	margin : 0 5px;
	color: white;
	background-color: transparent;
	border: 0.5px solid rgba(255, 255, 255, 0.534);
	border-radius: 7px;
	padding: 3px;
  min-width: 50%;
  max-width: 50%;
}
.inputFieldBig{
	margin : 0 5px;
	color: white;
	background-color: transparent;
	border: 0.5px solid rgba(255, 255, 255, 0.534);
	border-radius: 7px;
	padding: 3px;
  min-width: 50%;
  max-width: 50%;
  min-height: 10vh;
  max-height: 10vh;
}
.redSection {
  position: absolute;
  border-radius: 5px;
  left: 0;
  transform: translateY(-0.5%);
  min-height: 80vh;
  background-color: #CC0000;
  z-index: 1002;
  min-width: 25vw;
  max-width: 25vw;
  min-height: 68vh;
  max-height: 68vh;
  padding: 10px 10px;
}
.Right__RedSection{
  position: absolute;
  border-radius: 5px;
  right: 0;
  transform: translateY(-0.5%);
  min-height: 80vh;
  background-color: #CC0000;
  z-index: 1002;
  min-width: 25vw;
  max-width: 25vw;
  min-height: 68vh;
  max-height: 68vh;
  padding: 10px 10px;
}
.oneInput {
  font-size: 15px;
	color: white;
  display: flex;
  flex-wrap: wrap;
  min-width: 23vw;
  max-width: 27vw;
  justify-content: right;
  margin: 15px 5px;
  border-radius: 8px;
  background-color: #ffa500;
  border: 2px #ffa500 solid;
  padding: 5px;
}
.write_a_review{
  display: block;
  font-size: 20px;
  color: white;
  margin: 10px;
  margin-top: 20px;
  font-weight: bold;
  
}
.submit_button_holder{
  display: flex;
  justify-content: center;
  align-items: center;
}
.submit_button{
  cursor: pointer;
  padding: 5px 0;
  font-size: 15px;
  border: 0px;
  background-color: white;
  border-radius: 500px;
  width: 40%;

}
.leaflet-popup-content-wrapper{
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  overflow-x: auto;
}
.leafmapPictureAll {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  min-width: 10vw;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-bottom: 10px;
}

.leafmapPicture {
  max-height: 15vh;
  min-height: 15vh;
  border-radius: 5px;
  /* border-bottom:1px solid #ff8400; */
}
.leaflet-container {
  width: 100vw;
  height: 70vh;
}
.locButton{
  position: absolute;
  cursor: pointer;
  z-index: 1002 !important;
  position: absolute;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #ffa500;
  border-radius: 10px;
  padding: 5px 0 5px 0;
  margin-left: 10px;
  transform: translate(0,150%);
}
.close_icon{
  max-height: 10px;
  min-height: 10px;
  position: absolute;
  transform: translateX(3300%);
  cursor: pointer;
}
.right_close_icon{
  max-height: 10px;
  min-height: 10px;
  position: absolute;
  cursor: pointer;
}
.review_box{
  padding: 0px 10px;
  color: white;
}
.review_box_oneDesc{
  font-size: 10px;
}
.review_box_onePicture{
  max-width: 20px;
  padding: 0 5px;
}
.review_box_desc{
  padding: 5px;
  border: 1px solid white;
  border-radius: 5px;
  margin: 10px 0;
  align-items: center;
  text-align: left;
}
.review_box_rating{
  padding: 2px;
  align-items: center;
  display: flex;
  text-align: left;
}
.review_box_revs{
  margin : 10px 0;
  text-align: left;
  overflow-y: scroll;
  max-height: 40vh;
  min-height: -webkit-max-content;
  min-height: max-content;
}
.review_box_revs_entire{
  padding: 7px 4px;
  border: 1px solid white;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin:2px;
  width: 95%;
}
.review_box_revs_rat{
  display: flex;
  align-items:flex-start;
  margin: 0 10px 0 0 ;
}
.review_box_revs_text{
    background: #05050517;
  border-radius: 10px;
    font-size: 13px;
  color: #ffce52;
  border-left:1px solid white;
  align-items: center;
  display: flex;
  padding: 0 10px 0 10px;
  max-width: 70%;
}
.all_user_places{
  max-width: 80%;
  min-width: 85%;
  border-radius: 10px;
  display: flex;
  justify-content:left;
  overflow-x: scroll;
}
.sliderContainer{
    background-color: #ffa500;
    margin: 2px 0;
}
.sliderHeader{
    padding: 5px 15px;
    color: white;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
.sliderWhole{
    margin: 0 auto;
    padding: 10px 0 20px 0;
    display: flex;
    background-color: transparent;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 95vw;
    align-items: center;
}
.slider{
    display: flex;
    border-radius: 5px;
    background-color: transparent;
    overflow-x: hidden;
    justify-content: space-between;
    max-width: 90vw;
    scroll-behavior: smooth;
}
.sliderIcon{
    margin : 0 5px 0 5px;
    cursor: pointer;
    max-height: 3vw;
    min-height: 3vw;
    max-width: 3vw;
    min-width: 3vw;
}
.oneBox{
    cursor: pointer;
    box-shadow: rgba(91, 91, 121, 0.25) 0px 13px 27px -5px, rgba(100, 100, 100, 0.603) 0px 8px 16px -8px;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    color: #CC0000;
    margin: 0 5px;
    max-width: 18vw;
    min-width: 18vw;
    max-height: 30vh;
    overflow: hidden;
}
.NoPicture{
    max-width: 100%;
    max-height: 120px;
}
.onePicture{
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    max-height: 120px;
    min-height: 120px;
    min-width: 100%;
}
.oneName{
    font-size: 20px;
    font-weight: 700;
}
.oneAddress{
    font-size: 12px;
    font-weight: 400;
}   
.AddPlace_dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001 !important;
  width: 50%;
  height: -webkit-max-content;
  height: max-content;
  margin:  0 7px;
  transition: all 0.2s;
  border: 0.5px solid rgba(255, 255, 255, 0.534);
	border-radius: 7px;
  background-color: transparent;  
  color: white;
}
.AddPlace_dropdown__btn {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 12px;
  color: white;
  margin : 2px;
  width: 100%;
  min-height: 20px;
  max-height: 20px;
  padding: 0 8px;
}
.AddPlace_dropdown__contentdrawer{
  position: absolute;
  justify-content: center;
  font-size: 11px;
  padding: 10px;
  background: white;
  border-radius: 5px;
  color: #ffa500;
  top: 61.5%;
  transition: all 0.2s;
  min-height: 20%;
  max-height: 20%;
  min-width: 38%;
  max-width: 38%;
  overflow-y: scroll;
}
.AddPlace_dropdown__item{
  padding: 2px 0;
  cursor: pointer;
  transition: all 0.2s;
}

.AddPlace_dropdown .AddPlace_dropdown__contentdrawer .AddPlace_dropdown__item:hover {
  background: #e6e6e6f8;
}
/*.AddPlace_dropdown .AddPlace_dropdown__contentdrawer {
  position: absolute;
  justify-content: center;
  top: 160%;
  font-size: 11px;
  left: 0;
  padding: 10px;
  background: white;
  border-radius: 5px;
  color: #ffa500;
  width: 90%;
}
.AddPlace_dropdown .AddPlace_dropdown__contentdrawer .AddPlace_dropdown__item {
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
}



.AddPlace_dropdown__contentdrawer{
  min-height: 580%;
  max-height: 580%;
  overflow-x: hidden;
  overflow-y: scroll;
} */

.navbar {
  background-color: #ffa500;
}
.navbar__container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5%;
  justify-content: space-between;
}
.navbar__logo__area {
  display: flex;
  width: 15%;
}
.icon {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.iconText {
  display: flex;
  padding: 0px 10px;
  font-size: 20px;
  font-weight: 700;
  align-items: center;
}

.search__area {
  width: 50%;
  transform: translateX(-10%);
  z-index: 1002 !important;
}

.auth__buttons {
  display: flex;
  width: 20%;
  justify-content: space-between;
  text-align: center !important;
}

.login__btn {
  text-decoration: none;
  display: flex;
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  margin: 7px 0px;
  color: #ffa500;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  background-color: white;
  align-items: center;
  box-shadow: 0px 6px 5px 5px #05050517;
  margin: 5px 0px;
  text-align: center;
}
.single__auth__button{
  display: flex;
  width: 20%;
  justify-content: right;
  margin-top: 4px;
}
.profile__btn {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  max-height: 40px;
  font-size: 15px;
  color: white;
  justify-content: right;
  padding: 0 5px;
  border-radius: 25px;
  background-color: transparent;
  align-items: center;
  box-shadow: 0px 6px 5px 5px #05050517;
  border: 1px solid white;
  padding: 0 0 0 10px;
}
.user__icon{
  margin: 0 10px;
  border-radius: 50px;
  max-width: 25%;
  max-height: 75%;
}
.profile__close{
  position: absolute;
  max-width: 15px;
  max-height: 15px;
  transform: translateX(600%);
  cursor: pointer;
}
.profileWhole{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  background-color: white;
  z-index: 1002 !important;
  max-width:15vw;
  border-radius: 5px;
  padding: 5px;
  transform: translate(470%,17%);
  transition: linear ;
}
.profile__one__item{
  min-width: 100%;
  align-self: center;
  border-radius: 2px;
  padding: 2px 5px;
  margin: 4px 2px;
  font-size: 16px;
  color: #ffa500;
  text-align: center;
}
.profile__one__item__logout{
  cursor: pointer;
  min-width: 80%;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 20px;
  padding: 2px 5px;
  margin: 4px 2px;
  font-size: 16px;
  color: white;
  background-color: #ffa500;
  text-align: center;
}
.profile__picture{
  background-color: #ffa6009a;
  border-radius: 200px;
  margin: 5px;
  text-align: center;
  max-height: 10vh;
}
.profile__one__subitem{
  display: flex;
  min-width: 100%;
  margin: 2px 2px;
}
.profile__one__boxleft{
  text-align: right;
  min-width: 20%;
  max-width: 20%;
  margin: 3px 2px;
  font-size: 12px;
  color: #ffa500;
  border-radius: 2px ;
  padding: 2px 0px;

}
.profile__one__boxright{
  text-align: left;
  min-width: 65%;
  max-width: 65%;
  overflow:hidden ;
  margin: 3px 2px;
  font-size: 12px;
  color: #ffa500;
  border-radius: 2px ;
  background-color: whitesmoke;
  padding: 2px 5px;
}
.signup__btn {
  text-decoration: none;
  display: flex;
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  margin: 7px 0px;
  color: #ffa500;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  background-color: white;
  align-items: center;
  box-shadow: 0px 6px 5px 5px #05050517;
  margin: 5px 0px;
  text-align: center;
}
.dropdown {
  position: relative;
  z-index: 1001 !important;
  width: 30%;
  padding-left: 10px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #3a3a3a;
  margin: 0 0;
  border-right: 2px solid #f00500;
  align-items: center;
  cursor: pointer;
}
.dropdown .dropdown__btn {
  display: flex;
  cursor: pointer;
  font-size: 12px;
  padding: 0px 10px;
  height: 100%;
  align-items: center;
  color: #ffa500;
}
.dropdown .dropdown__contentdrawer {
  position: absolute;
  justify-content: center;
  top: 160%;
  font-size: 11px;
  left: 0;
  padding: 10px;
  background: white;
  border-radius: 5px;
  color: #ffa500;
  width: 90%;
}
.dropdown .dropdown__contentdrawer .dropdown__item {
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown .dropdown__contentdrawer .dropdown__item:hover {
  background: #e6e6e6f8;
}

.dropdown__contentdrawer{
  min-height: 580%;
  max-height: 580%;
  overflow-x: hidden;
  overflow-y: scroll;
}

  .searchWhole {
    max-height:3vh;
    width: 100%;
    display: flex;
    justify-content: left;
    background-color: transparent;
  }
  .product__search {
    border: 0px;
    color: gray;
    width: 100%;
  }
  .resultBox__search {
    position: absolute !important;
    width: 22.5vw;
    z-index: 990 !important;
    display: flex;
    justify-content: center;
    margin-top: 1vh;
    border: 0px;
  }

  textarea:focus, input:focus{
    outline: none;
}

  .results__search {
      display: flex;
      flex-wrap: wrap;
    min-width: 100%;
    max-width: 100%;
    padding: 5px;
    border: 0px solid #1a3a5c;
    background-color: white;
    border-radius: 2px;
    font-size: 20px;
    color: #ffa500;
    z-index: 990 !important;
  }
  .oneSearch:hover {
    background-color: rgba(196, 196, 196, 0.719);
    border-radius: 5px;
  }
  .search_icon{
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 20px;
    width: 20px;
  }
  .oneSearch {
    cursor: pointer;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
  }
  .text {
    color: #ffa500;
    font-size: 15px;
  }
  .smalltext {
    padding: 0.5px;
    color: gray;
    font-size: 10px;
  }
  .allText {
    max-width: 75%;
  }
.search__bar {
  display: flex;
  width: 100%;
  padding: 5px 0px;
}
.searchContainer {
  width: 100%;
}

.form__group {
  display: flex;
  height: 20px;
  background-color: white;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0px 6px 5px 5px #05050517;
}

.dd {
  font-size: 12px;
  text-align: left;
}

.search {
  padding: 0 10px 0 10px;
  width: 70%;
  height: 100%;
  border: none;
}

.search__input {
  width: 90%;
  height: 100%;
  font-size: 15px;
  border: 0;
  border-style: none;
  outline: none;
  padding: 0 10px;
  color: #8d8c8c;
}

.btn {
  background-color: #fff;
  color: black;
  width: 30px;
  height: 100%;
  border: 0;
  position: relative;
  right: 10;
  cursor: pointer;
}

.btn .fa-search {
  font-size: 17px;
  color: #b8b6b6f8;
}

.btn .fa-search:hover {
  font-size: 18px;
  color: #f00500;
}

*{
  scroll-behavior: smooth;
}
.home {
  background-color: #f00500;
  margin: auto;
}
.map {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: space-around;
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar { 
  position: absolute;
  z-index: 1005 !important;
  width: 10px;
  border-radius: 5px;
  height: 0px;
  }
  ::-webkit-scrollbar-track {
  background: #ffa500;
  border: 0px ;
  }
    ::-webkit-scrollbar-thumb {
  background: #ff6000;
  border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
  background: red;
  }
  ::-webkit-scrollbar-thumb:active {
  background: red;
  /* -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.3); */
  }

* {
  font-family: "Open Sans", sans-serif;
}
.login_whole {
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
}
.login_container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 70vh;
  max-height: 70vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20%;
  min-width: 60vw;
  max-width: 60vw;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  flex-wrap: wrap;
}
.image_otherhalf {
  width: 30vw;
  overflow: hidden;
  background-color: transparent;
}
.login_image {
  /* max-width: 100%; */
  /* min-width: 100%; */
  min-height: 70vh;
  max-height: 70vh;
}
.login_otherhalf {
  width: 30vw;
  overflow: hidden;
}
.login_heading {
  min-width: 100%;
  font-size: 40px;
  max-height: 10vh;
  margin-top: 10%;
}
.login_InputSection {
    overflow-x: scroll;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 30px auto;
  display: flex;
  padding: 20px 0;
  min-width: 85%;
  max-width: 85%;
  justify-content: center;
  font-size: 12px !important;
}
.login_InputText {
  max-height: 15vh;
  min-height: 15vh;
  max-width: 25%;
  min-width: 25%;
  margin: 2.5px;
}
.login_InputText > span {
  display: block;
  text-align: left;
  margin: 16px 5px;
  padding: 5px 10px;
}
.login_InputFields {
  max-height: 15vh;
  min-height: 15vh;
  margin: 2.5px;
  min-width: 70%;
  max-width: 70%;
}
.login_oneInputBox {
  background-color: rgba(194, 194, 194, 0.5);
  border: 0px;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 15px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  color: black;
}
.login_buttonBox {
  margin: auto;
  min-width: 85%;
  max-width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.login_buttonBox > span {
  font-size: 12px;
  padding: 5px;
}
.login_button {
  padding: 5px;
  border-radius: 5px;
  border: 0px;
  background-color: #ff8400;
  height: 30px;
  width: 100%;
  cursor: pointer;
}
.login_button_container {
  cursor: pointer;
  border-radius: 5px;
  border: 0px;
  font-size: 12px;
  background-color: #ff8400;
  width: 100%;
}
.login_top_buttons {
  margin: 10px 0 0 10px;
  min-width: 25%;
  max-width: 25%;
}
.login_button_top {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
  border: 0px;
  background-color: #ff8400;
  cursor: pointer;
}
.login_back_button {
  width: 20px;
  height: 20px;
}

* {
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}
.signup_whole {
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
}
.signup_container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 80vh;
  max-height: 80vh;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20%;
  min-width: 60vw;
  max-width: 60vw;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
  flex-wrap: wrap;
}
.image_otherhalf {
  width: 30vw;
  overflow: hidden;
  background-color: transparent;
}
.signup_image {
  min-height: 80vh;
  max-height: 80vh;
}
.signup_otherhalf {
  width: 30vw;
  overflow: hidden;
}
.signup_heading {
  min-width: 100%;
  font-size: 40px;
  max-height: 10vh;
  margin-top: 5%;
}
.signup_InputSection {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 15px auto;
  display: flex;
  padding: 20px 0;
  flex-wrap: wrap;
  width: 95%;
  justify-content: center;
  font-size: 12px !important;
}
.signup_InputText {
  max-height: 50vh;
  width: 35%;
  margin: 2.5px;
}
.signup_InputText > span {
  display: block;
  text-align: left;
  margin: 16px 5px;
  padding: 5px 10px;
}
.signup_InputFields {
  max-height: 50vh;
  margin: 2.5px;
  width: 60%;
}
.signup_oneInputBox {
  background-color: rgba(194, 194, 194, 0.5);
  border: 0px;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 15px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  color: black;
}
.signup_buttonBox {
  margin: auto;
  padding-top: 20px;
  min-width: 85%;
  max-width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.signup_buttonBox > span {
  font-size: 12px;
  padding: 5px;
}
.signup_button {
  padding: 5px;
  border-radius: 5px;
  border: 0px;
  background-color: #ff8400;
  height: 30px;
  width: 100%;
  cursor: pointer;
}
.signup_button_container {
    cursor: pointer;
    margin: 10px auto;
    border-radius: 5px;
    border: 0px;
    font-size: 12px;
    background-color: #ff8400;
    width: 100%;
}
.signup_top_buttons{
    margin: 10px 0 0 10px;
    min-width:25%;
    max-width: 25%;
}
.signup_button_top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 5px 5px 18px ;
    border-radius: 5px;
    border: 0px;
    background-color: #ff8400;
    height: 30px;
    width: 100%;
    cursor: pointer;
  }
.signup_back_button{
    width: 20px;
    height: 20px;
}
.adminhome_container {
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
}
.sidebar {
  position: fixed;
  background-color: #ffa500;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 25%;
  max-width: 25%;
  padding: 5px;
}
.sidebar_object {
    display: flex;
    justify-content: center;
  padding: 10px;
  border: 1px solid white;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0px 6px 5px 5px #05050517;
  align-items: center;
  margin: 20px 10px;
  max-width: 80%;
  border-radius: 25px;
}
.sidebar_object:hover{
  background-color: #ffb500;
  box-shadow: 1px 1px 1px 1px rgba(255, 255, 255, 0.555);
}

.sidebar_text {
  font-size: 25px;
  color: white;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.admin_content {
  margin-left: 27%;
  min-width: 72%;
  max-width: 72%;
  background-color: white;
}

.allUsers {
  margin: 40px auto;
  border: 2px solid #ffa500;
  background-color: white;
  border-radius: 5px;
  min-width: 99%;
  max-width: 99%;
  overflow-x: scroll;
  padding: 5px;
}
.oneUser {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  border-radius: 5px;
  justify-content: stretch;
  align-items: center;
}
.oneUser_icon_start {
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  padding: 4px;
}
.oneUser_icon {
  cursor: pointer;
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px;
}
.oneUser_icon:hover {
  border: 1px solid #CC0000;
}
.oneUser_text {
  min-width: 22.5%;
  max-width: 22.5%;
  overflow: hidden;
  text-align: center;
  padding: 2px;
  border: 1px solid rgb(129, 129, 129);
  border-bottom: 1px solid rgb(129, 129, 129);
  font-size: 20px;
  color: black;
}
.pointsPanel {

  top: 50%;
  transform: translateY(-50%);
  left: 40%;
  min-width: 40%;
  position: absolute;
  background-color: rgb(212, 212, 212);
  border-radius: 10px;
  padding: 40px;
  border: 4px solid #ffa500;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.points_items{
  text-align: center;
  max-width: 80%;
  min-width: 80%;
}
.points_close {
  cursor: pointer;
  position: absolute;
  transform: translate(1150%, -280%);
  min-width: 4%;
  max-width: 4%;
}
.points_head {
  font-size: 18px;
  font-weight: bold;
}
.points_input {
  margin: 20px auto;
  max-width: 80%;
  min-width: 80%;
}
.points_input_box {
  margin: 0 20px;
  max-width: 40%;
  min-width: 40%;
  border: 0px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}
.points_button{
  border-radius: 10px;
  max-width: 40%;
  min-width: 40%;
  margin: 0px auto;
  border: 0px;
  background-color: #ffa500;
  padding: 5px;
  cursor: pointer;
}
.allPlaces {
  margin : 20px auto;
  border: 2px solid #ffa500;
  background-color: white;
  border-radius: 5px;
  min-width: 99%;
  max-width: 99%;
  overflow-x: scroll;
  padding: 5px;
}
.onePlace {
    min-width: 100%;
    max-width: 100%;
  display: flex;
  border-radius: 5px;
  justify-content:stretch;
  align-items: center;
}
.onePlace_icon_start {
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  padding: 4px;
}
.onePlace_icon {
  cursor: pointer;
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px;
}
.onePlace_icon:hover {
  border: 1px solid #CC0000;
}
.onePlace_text {
  min-width: 18%;
  max-width: 18%;
  overflow: hidden;
  text-align: center;
  padding: 2px;
  border: 1px solid rgb(129, 129, 129);
  border-bottom: 1px solid rgb(129, 129, 129);
  font-size: 20px;
  color: black;
}

