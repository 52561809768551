@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@350&display=swap");
* {
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
}
.signup_whole {
  min-width: 100vw;
  max-height: 100vh;
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
}
.signup_container {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 80vh;
  max-height: 80vh;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 20%;
  min-width: 60vw;
  max-width: 60vw;
  text-align: center;
  overflow: hidden;
  border-radius: 10px;
  height: fit-content;
  max-width: fit-content;
  display: flex;
  flex-wrap: wrap;
}
.image_otherhalf {
  width: 30vw;
  overflow: hidden;
  background-color: transparent;
}
.signup_image {
  min-height: 80vh;
  max-height: 80vh;
}
.signup_otherhalf {
  width: 30vw;
  overflow: hidden;
}
.signup_heading {
  min-width: 100%;
  font-size: 40px;
  max-height: 10vh;
  margin-top: 5%;
}
.signup_InputSection {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin: 15px auto;
  display: flex;
  padding: 20px 0;
  flex-wrap: wrap;
  width: 95%;
  justify-content: center;
  font-size: 12px !important;
}
.signup_InputText {
  max-height: 50vh;
  width: 35%;
  margin: 2.5px;
}
.signup_InputText > span {
  display: block;
  text-align: left;
  margin: 16px 5px;
  padding: 5px 10px;
}
.signup_InputFields {
  max-height: 50vh;
  margin: 2.5px;
  width: 60%;
}
.signup_oneInputBox {
  background-color: rgba(194, 194, 194, 0.5);
  border: 0px;
  border-radius: 5px;
  padding: 5px 15px;
  margin: 15px 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  color: black;
}
.signup_buttonBox {
  margin: auto;
  padding-top: 20px;
  min-width: 85%;
  max-width: 85%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.signup_buttonBox > span {
  font-size: 12px;
  padding: 5px;
}
.signup_button {
  padding: 5px;
  border-radius: 5px;
  border: 0px;
  background-color: #ff8400;
  height: 30px;
  width: 100%;
  cursor: pointer;
}
.signup_button_container {
    cursor: pointer;
    margin: 10px auto;
    border-radius: 5px;
    border: 0px;
    font-size: 12px;
    background-color: #ff8400;
    width: 100%;
}
.signup_top_buttons{
    margin: 10px 0 0 10px;
    min-width:25%;
    max-width: 25%;
}
.signup_button_top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px 5px 5px 18px ;
    border-radius: 5px;
    border: 0px;
    background-color: #ff8400;
    height: 30px;
    width: 100%;
    cursor: pointer;
  }
.signup_back_button{
    width: 20px;
    height: 20px;
}