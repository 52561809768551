.allPlaces {
  margin : 20px auto;
  border: 2px solid #ffa500;
  background-color: white;
  border-radius: 5px;
  min-width: 99%;
  max-width: 99%;
  overflow-x: scroll;
  padding: 5px;
}
.onePlace {
    min-width: 100%;
    max-width: 100%;
  display: flex;
  border-radius: 5px;
  justify-content:stretch;
  align-items: center;
}
.onePlace_icon_start {
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  padding: 4px;
}
.onePlace_icon {
  cursor: pointer;
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  border-radius: 5px;
  padding: 2px;
}
.onePlace_icon:hover {
  border: 1px solid #CC0000;
}
.onePlace_text {
  min-width: 18%;
  max-width: 18%;
  overflow: hidden;
  text-align: center;
  padding: 2px;
  border: 1px solid rgb(129, 129, 129);
  border-bottom: 1px solid rgb(129, 129, 129);
  font-size: 20px;
  color: black;
}
