  .searchWhole {
    max-height:3vh;
    width: 100%;
    display: flex;
    justify-content: left;
    background-color: transparent;
  }
  .product__search {
    border: 0px;
    color: gray;
    width: 100%;
  }
  .resultBox__search {
    position: absolute !important;
    width: 22.5vw;
    z-index: 990 !important;
    display: flex;
    justify-content: center;
    margin-top: 1vh;
    border: 0px;
  }

  textarea:focus, input:focus{
    outline: none;
}

  .results__search {
      display: flex;
      flex-wrap: wrap;
    min-width: 100%;
    max-width: 100%;
    padding: 5px;
    border: 0px solid #1a3a5c;
    background-color: white;
    border-radius: 2px;
    font-size: 20px;
    color: #ffa500;
    z-index: 990 !important;
  }
  .oneSearch:hover {
    background-color: rgba(196, 196, 196, 0.719);
    border-radius: 5px;
  }
  .search_icon{
    display: flex;
    cursor: pointer;
    align-items: center;
    height: 20px;
    width: 20px;
  }
  .oneSearch {
    cursor: pointer;
    max-width: 100%;
    min-width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid lightgray;
    padding-bottom: 5px;
  }
  .text {
    color: #ffa500;
    font-size: 15px;
  }
  .smalltext {
    padding: 0.5px;
    color: gray;
    font-size: 10px;
  }
  .allText {
    max-width: 75%;
  }