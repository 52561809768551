*{
  scroll-behavior: smooth;
}
.home {
  background-color: #f00500;
  margin: auto;
}
.map {
  display: flex;
  height: fit-content;
  justify-content: space-around;
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar { 
  position: absolute;
  z-index: 1005 !important;
  width: 10px;
  border-radius: 5px;
  height: 0px;
  }
  ::-webkit-scrollbar-track {
  background: #ffa500;
  border: 0px ;
  }
    ::-webkit-scrollbar-thumb {
  background: #ff6000;
  border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
  background: red;
  }
  ::-webkit-scrollbar-thumb:active {
  background: red;
  /* -webkit-box-shadow: inset 1px 1px 2px rgba(0,0,0,0.3); */
  }
