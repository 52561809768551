.search__bar {
  display: flex;
  width: 100%;
  padding: 5px 0px;
}
.searchContainer {
  width: 100%;
}

.form__group {
  display: flex;
  height: 20px;
  background-color: white;
  border-radius: 25px;
  padding: 10px;
  box-shadow: 0px 6px 5px 5px #05050517;
}

.dd {
  font-size: 12px;
  text-align: left;
}

.search {
  padding: 0 10px 0 10px;
  width: 70%;
  height: 100%;
  border: none;
}

.search__input {
  width: 90%;
  height: 100%;
  font-size: 15px;
  border: 0;
  border-style: none;
  outline: none;
  padding: 0 10px;
  color: #8d8c8c;
}

.btn {
  background-color: #fff;
  color: black;
  width: 30px;
  height: 100%;
  border: 0;
  position: relative;
  right: 10;
  cursor: pointer;
}

.btn .fa-search {
  font-size: 17px;
  color: #b8b6b6f8;
}

.btn .fa-search:hover {
  font-size: 18px;
  color: #f00500;
}
