.dropdown {
  position: relative;
  z-index: 1001 !important;
  width: 30%;
  padding-left: 10px;
  user-select: none;
  color: #3a3a3a;
  margin: 0 0;
  border-right: 2px solid #f00500;
  align-items: center;
  cursor: pointer;
}
.dropdown .dropdown__btn {
  display: flex;
  cursor: pointer;
  font-size: 12px;
  padding: 0px 10px;
  height: 100%;
  align-items: center;
  color: #ffa500;
}
.dropdown .dropdown__contentdrawer {
  position: absolute;
  justify-content: center;
  top: 160%;
  font-size: 11px;
  left: 0;
  padding: 10px;
  background: white;
  border-radius: 5px;
  color: #ffa500;
  width: 90%;
}
.dropdown .dropdown__contentdrawer .dropdown__item {
  padding: 5px;
  cursor: pointer;
  transition: all 0.2s;
}

.dropdown .dropdown__contentdrawer .dropdown__item:hover {
  background: #e6e6e6f8;
}

.dropdown__contentdrawer{
  min-height: 580%;
  max-height: 580%;
  overflow-x: hidden;
  overflow-y: scroll;
}
