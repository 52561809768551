.navbar {
  background-color: #ffa500;
}
.navbar__container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 5%;
  justify-content: space-between;
}
.navbar__logo__area {
  display: flex;
  width: 15%;
}
.icon {
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
}
.iconText {
  display: flex;
  padding: 0px 10px;
  font-size: 20px;
  font-weight: 700;
  align-items: center;
}

.search__area {
  width: 50%;
  transform: translateX(-10%);
  z-index: 1002 !important;
}

.auth__buttons {
  display: flex;
  width: 20%;
  justify-content: space-between;
  text-align: center !important;
}

.login__btn {
  text-decoration: none;
  display: flex;
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  margin: 7px 0px;
  color: #ffa500;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  background-color: white;
  align-items: center;
  box-shadow: 0px 6px 5px 5px #05050517;
  margin: 5px 0px;
  text-align: center;
}
.single__auth__button{
  display: flex;
  width: 20%;
  justify-content: right;
  margin-top: 4px;
}
.profile__btn {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  max-width: fit-content;
  max-height: 40px;
  font-size: 15px;
  color: white;
  justify-content: right;
  padding: 0 5px;
  border-radius: 25px;
  background-color: transparent;
  align-items: center;
  box-shadow: 0px 6px 5px 5px #05050517;
  border: 1px solid white;
  padding: 0 0 0 10px;
}
.user__icon{
  margin: 0 10px;
  border-radius: 50px;
  max-width: 25%;
  max-height: 75%;
}
.profile__close{
  position: absolute;
  max-width: 15px;
  max-height: 15px;
  transform: translateX(600%);
  cursor: pointer;
}
.profileWhole{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  background-color: white;
  z-index: 1002 !important;
  max-width:15vw;
  border-radius: 5px;
  padding: 5px;
  transform: translate(470%,17%);
  transition: linear ;
}
.profile__one__item{
  min-width: 100%;
  align-self: center;
  border-radius: 2px;
  padding: 2px 5px;
  margin: 4px 2px;
  font-size: 16px;
  color: #ffa500;
  text-align: center;
}
.profile__one__item__logout{
  cursor: pointer;
  min-width: 80%;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 20px;
  padding: 2px 5px;
  margin: 4px 2px;
  font-size: 16px;
  color: white;
  background-color: #ffa500;
  text-align: center;
}
.profile__picture{
  background-color: #ffa6009a;
  border-radius: 200px;
  margin: 5px;
  text-align: center;
  max-height: 10vh;
}
.profile__one__subitem{
  display: flex;
  min-width: 100%;
  margin: 2px 2px;
}
.profile__one__boxleft{
  text-align: right;
  min-width: 20%;
  max-width: 20%;
  margin: 3px 2px;
  font-size: 12px;
  color: #ffa500;
  border-radius: 2px ;
  padding: 2px 0px;

}
.profile__one__boxright{
  text-align: left;
  min-width: 65%;
  max-width: 65%;
  overflow:hidden ;
  margin: 3px 2px;
  font-size: 12px;
  color: #ffa500;
  border-radius: 2px ;
  background-color: whitesmoke;
  padding: 2px 5px;
}
.signup__btn {
  text-decoration: none;
  display: flex;
  width: 45%;
  font-size: 15px;
  font-weight: bold;
  margin: 7px 0px;
  color: #ffa500;
  justify-content: center;
  border-radius: 25px;
  cursor: pointer;
  background-color: white;
  align-items: center;
  box-shadow: 0px 6px 5px 5px #05050517;
  margin: 5px 0px;
  text-align: center;
}