.container{
    background-color: #ffa500;
    width: 100%;
}
.social_icons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.modal__links{
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.one__modal__link{
    cursor: pointer;
    margin: 10px 40px;
    font-size: 14px;
    color: white;
    transition: transform 0.2s;
    text-decoration: underline;

}
.one__modal__link:hover{
transform: scale(1.4);
    color: #CC0000;
    text-decoration: none;
}
.contact__us{
    text-align: center;
    min-width: 100%;
    margin: 20px auto 30px auto;
    font-size: 14px;
    color: #CC0000;
}
.one_icon{
    width: 40px;
    margin: 40px 10px 10px 10px ;
    transition: transform 0.2s;
}
.one_icon:hover{
    background-color: white;
    border-radius: 500px;
    transform: scale(1.5) translateY(-30%);
    box-shadow: 3px 2px 3px  rgba(255, 255, 255, 0.521);
}
.modal__container{    
    transform: translateY(-150%) translateX(30%);
    min-width: 60%;
    max-width: 60%;
    min-height: 40vh;
    max-height: 40vh;
    position: absolute;
    z-index: 999 !important;
    border: 2px solid #ffa500;
    border-radius: 10px;
    background-color: #CC0000;
    padding: 10px;
    margin: auto;
    overflow-y: scroll;
}
.modal__title{    
    min-width: 100%;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: white;
}
.modal__body{
    margin: 25px 0 10px 0;
    min-width: 100%;
    max-width: 100%;
    text-align: left;
    font-size: 15px;
    color: white;
}