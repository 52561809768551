.sliderContainer{
    background-color: #ffa500;
    margin: 2px 0;
}
.sliderHeader{
    padding: 5px 15px;
    color: white;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
.sliderWhole{
    margin: 0 auto;
    padding: 10px 0 20px 0;
    display: flex;
    background-color: transparent;
    flex-wrap: wrap;
    justify-content: center;
    min-width: 95vw;
    align-items: center;
}
.slider{
    display: flex;
    border-radius: 5px;
    background-color: transparent;
    overflow-x: hidden;
    justify-content: space-between;
    max-width: 90vw;
    scroll-behavior: smooth;
}
.sliderIcon{
    margin : 0 5px 0 5px;
    cursor: pointer;
    max-height: 3vw;
    min-height: 3vw;
    max-width: 3vw;
    min-width: 3vw;
}
.oneBox{
    cursor: pointer;
    box-shadow: rgba(91, 91, 121, 0.25) 0px 13px 27px -5px, rgba(100, 100, 100, 0.603) 0px 8px 16px -8px;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
    color: #CC0000;
    margin: 0 5px;
    max-width: 18vw;
    min-width: 18vw;
    max-height: 30vh;
    overflow: hidden;
}
.NoPicture{
    max-width: 100%;
    max-height: 120px;
}
.onePicture{
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    max-height: 120px;
    min-height: 120px;
    min-width: 100%;
}
.oneName{
    font-size: 20px;
    font-weight: 700;
}
.oneAddress{
    font-size: 12px;
    font-weight: 400;
}   