.allUsers {
  margin: 40px auto;
  border: 2px solid #ffa500;
  background-color: white;
  border-radius: 5px;
  min-width: 99%;
  max-width: 99%;
  overflow-x: scroll;
  padding: 5px;
}
.oneUser {
  min-width: 100%;
  max-width: 100%;
  display: flex;
  border-radius: 5px;
  justify-content: stretch;
  align-items: center;
}
.oneUser_icon_start {
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  padding: 4px;
}
.oneUser_icon {
  cursor: pointer;
  min-width: 2.5%;
  max-width: 2.5%;
  border: 1px solid white;
  border-radius: 4px;
  padding: 4px;
}
.oneUser_icon:hover {
  border: 1px solid #CC0000;
}
.oneUser_text {
  min-width: 22.5%;
  max-width: 22.5%;
  overflow: hidden;
  text-align: center;
  padding: 2px;
  border: 1px solid rgb(129, 129, 129);
  border-bottom: 1px solid rgb(129, 129, 129);
  font-size: 20px;
  color: black;
}
.pointsPanel {

  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 40%;
  min-width: 40%;
  position: absolute;
  background-color: rgb(212, 212, 212);
  border-radius: 10px;
  padding: 40px;
  border: 4px solid #ffa500;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}
.points_items{
  text-align: center;
  max-width: 80%;
  min-width: 80%;
}
.points_close {
  cursor: pointer;
  position: absolute;
  transform: translate(1150%, -280%);
  min-width: 4%;
  max-width: 4%;
}
.points_head {
  font-size: 18px;
  font-weight: bold;
}
.points_input {
  margin: 20px auto;
  max-width: 80%;
  min-width: 80%;
}
.points_input_box {
  margin: 0 20px;
  max-width: 40%;
  min-width: 40%;
  border: 0px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}
.points_button{
  border-radius: 10px;
  max-width: 40%;
  min-width: 40%;
  margin: 0px auto;
  border: 0px;
  background-color: #ffa500;
  padding: 5px;
  cursor: pointer;
}